.sec-wrap {
    padding-left: 0% !important;
    padding-right: 0% !important;
    background-color: #0B161E;
}

.table-head {
    padding-bottom: 2%;
    padding-top: 2%;
    background-color: #1D2229;
}

.table> :not(caption)>*>* {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 1%;
    padding-right: 1%;
}

.table-title-wrap {
    display: flex;
    align-items: center;
    padding-left: 2% !important;
}

#table-title {
    color: #ffff;
    font-family: 'Proxima Nova';
    font-weight: 700;
}

.table-dark {
    --bs-table-bg: #1D2229;
}

.table-striped>tbody>tr:nth-of-type(2n+1)>* {
    --bs-table-accent-bg: #313843;
}

.search-row {
    background-color: #313843;
    display: flex;
    align-items: center;
    border-radius: 6px;
}

.search-icon {
    text-align: center;
}

.search-input {
    background-color: #313843 !important;
    border: none !important;
    transition: none !important;
    color: #ffff !important;
    font-family: 'Proxima Nova';
    font-size: smaller;
}

.search-input:focus {
    box-shadow: none !important;
}

.table-section {
    padding: 5% 5% 5% 5%;
}

.notify-button-wrap {
    display: flex;
    justify-content: center;
}

.notify-button {
    vertical-align: middle;
    background-color: #7A31E3;
    border: none;
    font-family: 'Proxima Nova';
    font-size: smaller;
}

.notify-button:hover {
    background-color: #7A31E3;
}

@media screen and (max-width: 992px) {
    .notify-button {
        font-size: x-small;
    }

    .search-input {
        font-size: x-small;
    }

}

@media screen and (max-width: 768px) {
    #table-title {
        text-align: center;
    }

    .notify-button-wrap {
        margin-top: 5%;
    }

    .table-title-wrap {
        display: inline;
    }

    .table-head {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .search-row {
        margin-top: 5%;
        margin-left: 20%;
        margin-right: 20%;
    }

}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    border-radius: 0px !important;
    background-color: #fff0 !important;
}

.css-1ygcj2i-MuiTableCell-root {
    color: white !important;
    font-size: medium !important;
    font-family: 'Proxima Nova' !important;
    font-weight: 600 !important;
}

.css-1ex1afd-MuiTableCell-root {
    color: white !important;
    border: none !important;
}

MuiTableHead-root {
    border: none !important;
}

.css-jtlhu6-MuiTablePagination-root {
    color: white !important;
}

.table> :not(caption)>*>* {
    padding-left: 15px;
    padding-right: 15px;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
    fill: white !important;
}

.loader {
    margin-top: 25vh;
    margin-bottom: 25vh;

}

.pagination {
    padding-top: 20px;
    color: white;
    display: flex;
    justify-content: end;
}

.css-pdct74-MuiTablePagination-selectLabel{
    margin-top: auto!important;
}

.css-levciy-MuiTablePagination-displayedRows{
    margin-top: auto!important;
}