.sec-wrap{
    overflow: hidden;
}

.row > * {
    padding-right: 0%!important;
    padding-left: 0%!important;
}

#login-img{
    width: 100%;
}

.second{
    background-color: #1D2229;
}

#login-form{
    padding-left: 15%;
    padding-right: 15%;
}

.login-title{
    text-align: left;
    color: #ffff;
    font-family: 'Proxima Nova';
    padding-bottom: 10%;
    
}

::placeholder{
    color: #CFCFCF;
}

.form-outline{
    padding-bottom: 10%;
}

input[type="email"], input[type="password"] {

    background-color : #1D2229;
    border-color: #CFCFCF;
    color: #ffff; 
  
}

input[type="email"]:focus, input[type="password"]:focus {

    box-shadow: none;
    background-color : #1D2229;
    border-color: #CFCFCF;
    color: #ffff; 
  
}

input[type="checkbox"]{
    background-color: #1D2229;
    border-color: #CFCFCF;
}

input[type="checkbox"]:checked{
    border: none;
}

input[type="checkbox"]:focus{
    border-color: #CFCFCF;
}

.form-check-label{
    color: #ffff;
    font-family: 'Proxima Nova';
    font-size: smaller;
    text-align: center;
}

.forgot{
    display: flex;
    justify-content: center;    
}

.forgot a{
    font-family: 'Proxima Nova';
    color: #7A31E3;
    font-size: smaller;
    text-decoration: none;
}

.forgot a:hover{
    color: #7A31E3;
}

.sub{
    width: 100%!important;
    background-color: #7A31E3!important;
    text-decoration-color: #ffff!important;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none!important;
}

@media screen and (max-width: 768px) {
    .sec{
        flex-flow: column-reverse;
    }

    .second{
        padding-bottom: 10%!important;
    }

}

@media screen and (min-width: 992px) {
    .second{
        padding-top: 20%!important;
    }

}

@media screen and (max-width: 992px) {
    .second{
        padding-top: 10%!important;
    }

}