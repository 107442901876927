.section-wrap{
    background-color: #0B161E;
    padding-left: 0%!important;
    padding-right: 0%!important;
    padding-bottom: 2%;
}

.section-row{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
    /* margin-bottom: 2%; */
    background-color: #1D2229;
}

#notification-form{
    padding: 5% 5% 5% 5%;
}

#notification{
    color: #ffff;
    font-family: 'Proxima Nova';
    font-weight: 700;
    padding-bottom: 20px;
}

.inp{
    margin-bottom: 30px;
}

input[type="text"]{
    background-color: #1D2229;
    border-color: #CFCFCF;
    color: #ffff;
}

input[type="text"]:focus{
    background-color: #1D2229;
    border-color: #CFCFCF;
    box-shadow: none;
    color: #ffff;
}

input[type="url"]{
    background-color: #1D2229;
    border-color: #CFCFCF;
    color: #ffff;
}

input[type="url"]:focus{
    background-color: #1D2229;
    border-color: #CFCFCF;
    box-shadow: none;
    color: #ffff;
}

textarea.form-control{
    background-color: #1D2229;
    border-color: #CFCFCF;
    color: #ffff;
}

textarea.form-control:focus{
    background-color: #1D2229;
    border-color: #CFCFCF;
    box-shadow: none;
    color: #ffff;
}

input[type="email"]{
    background-color: #1D2229;
    border-color: #CFCFCF;
    color: #ffff;
}

input[type="email"]:focus{
    background-color: #1D2229;
    border-color: #CFCFCF;
    box-shadow: none;
    color: #ffff;
}

input[type="file"]{
    background-color: #1D2229;
    border-color: #CFCFCF;
    color: #C0CCDA;
}

.form-check-input:checked {
    background-color: #7A31E3;
    border-color: #7A31E3;
}

.form-check-input:focus {
    box-shadow: none;
}


.labels{
    color: #C0CCDA;
    padding-bottom: 10px;
}

.togg{
    padding: 5% 5% 10% 5%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.lab{
    padding-left: 10px;
}

.preview{
    text-align: center;
    padding-top: 20%;
    padding-bottom: 70%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: #313843;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

#mobile_prev{
    width: 80%;
}

hr.solid {
    border-top: 2px solid #ffff;
}

.section-row1{
    padding-top: 1%;
    padding-bottom: 1%;
    margin-left: 5%;
    margin-right: 5%;
    background-color: #1D2229;
    display: flex;
    justify-content: end;
}

.buttons-sec{
    display: flex;
    justify-content: center;
}

.send{
    background-color: #7A31E3!important;
    text-decoration-color: #ffff!important;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border: none!important;
}

.cancel{
    border-color: #7A31E3;
    color: #7A31E3;
    text-decoration-color: #7A31E3;
    background-color: #1D2229;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 20px;
}

.cancel:hover{
    border-color: #7A31E3;
    color: #7A31E3;
    text-decoration-color: #7A31E3;
    background-color: #1D2229;
}

@media screen and (max-width: 768px) {
    .section-row1{
        display: flex;
        justify-content: center;
        padding-top: 6%;
    }
    
    hr.solid {
        display: none;
    }

}

.card{
    width: 65%;
    margin: auto;
}

.css-83ijpv-MuiTypography-root{
    text-align: left;
}

.css-et1ao3-MuiTypography-root{
    text-align: left;
}

.mobile_wrap{
    padding-top: 5%;
    background-color: #313843;
    margin-right: 5%;
}

.avatar_img{
    width: 40px;
    height: 40px;
}

.MuiPaper-elevation1{
    background-color: #1D2229!important;
}

.MuiTableCell-root{
    color: white!important;
}

MuiTableRow-root{
    color: white!important;
}