.head{
    background-color: #8242E6;
    height: 60px;
    padding-left: 2%;
    padding-right: 2%;
}

.logout{
    border: 1px solid white;
    padding: 5px 14px 5px 14px;
    background-color: #8242E6;
    color: #ffff;
    font-family: 'Proxima Nova';
    font-size: smaller;
}